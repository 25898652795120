.navbar-light .navbar-nav .nav-link {
    color: #007dc1;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #004d91;
}

.dropdown-item {
    color: #858a8d;
}

p {
    color: #858a8d;
}

.dropdown-item:focus, .dropdown-item:hover {
    background-color: #FFFFFF;
}

.dropdown-menu {

    border-top: 2px solid #004d91;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    box-shadow: 0 6px 15px rgba(0,0,0,0.12);
    margin: .5rem;
}

.navbar-expand-lg .navbar-collapse {
    padding-top: 22px;
}

.header-outer-container {
    padding-bottom:15px;
    margin-bottom:5px;
    border-bottom:1px solid #DBDBDB;
}

.header-inner-container {
  position:relative;
}

.btn-primary {
    background-color: #007dc1;
    border-color: #007dc1;
}

.form-control {
    border: 1px solid #858a8d;
}

.form {
    position: relative;
}

.overlay {
    background: white;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 555;
    opacity: 0.7;
}
.loading {
    width: 100%;
    height: 100%;
    position: absolute;
}

.col-form-label {
    color: #007dc1;
}

.header {
    color: #007dc1;
}

.dropdown-item:hover {
    color: #007dc1;
}

.card-text{
    color: white;
}

.card-body {
    color: white;
    background: #007dc1;
    border-bottom-left-radius: calc(.25rem - 1px);
    border-bottom-right-radius: calc(.25rem - 1px);
}

body {
    color: #858a8d
}



.home-jumbo-container {
  position: relative;
}

.home .home-jumbo {
    padding: 1rem 2rem;
    background-color:transparent;
    margin-bottom: 10px;
}

.home .home-jumbo h1, .home .home-jumbo p {
    color:white;
}

.home-jumbo-trans-overlay {
    background-color:black;
    opacity: 0.5;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    z-index: -40;
    height:100%;
}

.btn-fv {
  background-color:#D57831;
  color:white;
  border-radius: .2rem;
}

.btn-fv:hover {
  color:white;
}

.home-jumbo-container {
    margin-top:15px;
}

.card {
    width: 100%;
    height: 100px;
    padding: 2px;
    border: none;
    display: inline;
    display: initial;
}

.home-top {
    display:none;
}
.background {
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    float: left;
    width: 50%;
    height: 100%;
    cursor: pointer;
    background-position: center;
    border-radius: calc(.25rem - 1px) 0 0 calc(.25rem - 1px);
}

.title-box {
    -webkit-transition: background-color 350ms ease;
    transition: background-color 350ms ease;
    width: 50%;
    float: left;
    height: 100%;
    border-radius: 0 calc(.25rem - 1px) calc(.25rem - 1px) 0;
}
.card:hover .title-box {
    background-color: #D57831;
}
.color-1 {
    background-color: #005887;
}
.color-2 {
    background-color: #3D99CB;
}
.color-3 {
    background-color: #007dc1;
}
.color-4 {
    background-color: #009EF4;
}
.color-5 {
    background-color: #00456B;
}

.title {
    color: white;
    padding: 0 20px;
    line-height: 1.5;
    font-size: 12px;
}
.date {
    font-size: 8px;
    color: white;
    padding: 10px 20px 0 20px;
    margin-bottom: 0;
    opacity: .85;
}
.hover-text {
    color: white;
    font-size: 10px;

}
.display-over {
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    -webkit-transition: background-color 350ms ease;
    transition: background-color 350ms ease;
    background-color: transparent;
    padding: 20px;
    box-sizing: border-box;
    border-radius: calc(.25rem - 1px) 0 0 calc(.25rem - 1px);

}

.logo {
    width: 245px;
}
.icon-col h5 {
    margin-top:10px;
    color:white;
    font-weight: 400;
    font-size: 12px;
}

.icon {
    height: 32px;
}
@media (max-width: 348px) {
    .logo {
        width: 180px
    }
    .icon-col h5 {
        font-size: 10px;
    }
}

@media (min-width: 400px) {
    .home-jumbo-container {
        max-width: 380px;
    }
    .home-jumbo-container h1 {
        font-size:21px;
    }
    .home-jumbo-container p {
        font-size:14px;
    }
    .card {
        height: 175px;
        padding: 8px;
    }
    .grid {
        max-width: 380px;
    }
    .home-top {
        position:relative;
        padding:5px 0;
        margin-bottom:10px;
        display: block;
    }
    .title {
        font-size: 18px;
    }
    .date {
        font-size: 12px;
    }
    .hover-text {
        font-size: 16px;
    }
    .icon-col h5 {
        font-size: 16px;
    }

    .icon {
        height: 40px;
    }
}

@media (min-width: 460px) {
    .home-jumbo-container {
        max-width: 430px;
    }
    .home-jumbo-container h1 {
        font-size:26px;
    }
    .home-jumbo-container p {
        font-size:18px;
    }
    .card {
        width: 50%;
        height: 255px;
    }
    .background {
        width: 100%;
        height: 70%;
        border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
    }
    .title-box {
        width: 100%;
        height: 30%;
        border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
    }
    .title {
        font-size: 12px;
    }
    .date {
        font-size: 9px;
    }
    .hover-text {
        font-size: 12px;
    }
    .display-over {
        border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
    }
    .grid {
        max-width: 430px;
    }
}
@media (min-width: 576px) {
    .card{
        width: 50%;
        height: 330px;
    }
    .title {
        font-size: 16px;
    }
    .date {
        font-size: 12px;
    }
    .hover-text {
        font-size: 16px;
    }
    .container {
        max-width: 540px;
    }
    .icon-col h5 {
        font-size: 12px;
    }

    .icon {
        height: 36px;
    }
}
@media (min-width: 768px) {
    .card{
        width: 33.33%;
        height: 290px;
    }
    .title {
        font-size: 15px;
    }
    .date {
        font-size: 11px;
    }
    .hover-text {
        font-size: 15px;
    }
    .container {
        max-width: 720px;
    }
    .icon-col h5 {
        font-size: 18px;
    }

    .icon {
        height: 48px;
    }
}
@media (min-width: 992px) {
    .card {
        height: 380px;
    }
    .title {
        font-size: 20px;
    }
    .date {
        font-size: 15px;
    }
    .hover-text {
        font-size: 20px;
    }
    .container {
        max-width: 960px;
    }

}

.home-icon-strip {
    background-color:#D57831;
    width:100%;
    margin-top:20px;
}

.jumbo-background {
    background: url(/static/media/skyscraper-background.b63fd975.jpg);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    height:100%;
    width:100%;
    position:absolute;
    left:0;
    top:0;
    z-index:-50;
}

.icon-col a:hover {
    text-decoration: none;
}

.icon-col {
  margin-top:10px;
  text-align: center;
  padding:10px;
}
.footer {
    width: 100%;
    background-color: #858a8d;
    padding: 10px;
}
.card:hover .display-over {
    background-color: rgba(0,0,0,.5);
}
.card:hover .hover {
    opacity: 1;
}

.hover {
    opacity: 0;
    -webkit-transition: opacity 350ms ease;
    transition: opacity 350ms ease;
}

.container.grid {
    padding: 0px;
}

